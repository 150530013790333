const idsUsers = [
	"45ee1ab6-c7ab-4cd2-5ce7-08dac0f7448c",
	"caf9b834-0e64-4170-551c-08d962886480",
	"657d7200-6c6a-427d-b611-08db83d5dc24",
	"85483a83-92c0-4413-17d3-08dbc02518d6",
	"ea52a317-86f5-4826-673b-08dc370b29c3",
	"b25060d7-1cb6-4ce5-a684-08db1be17fd1",
	"31998649-c8f5-49c7-9e18-08d9b98384d9",
	"f9cef106-5359-4607-dfbc-08db942968b5",
	"3cc3fd1e-244d-4fd3-0639-08dcc82cbede"
];

export const verifyOpenRequestCards = (id: string) => {
	const findItem = idsUsers.find(i => i === id);

	return Boolean(findItem);
};
