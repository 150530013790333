import { modalAlert } from "Components/Modals/ModalAlert";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getErrors } from "helpers/error";
import _ from "lodash";
import * as api from "services/api";

dayjs.extend(customParseFormat);

export const GET_BUSINESS = "[BUSINESS DETAIL] GET_BUSINESS";
export const GET_BUSINESS_SUCCESS = "[BUSINESS DETAIL] GET_BUSINESS_SUCCESS";
export const GET_BUSINESS_FAILED = "[BUSINESS DETAIL] GET_BUSINESS_FAILED";

export const GET_BUSINESS_DAYS = "[BUSINESS DETAIL] GET_BUSINESS_DAYS";
export const GET_BUSINESS_DAYS_SUCCESS =
	"[BUSINESS DETAIL] GET_BUSINESS_DAYS_SUCCESS";
export const GET_BUSINESS_DAYS_FAILED =
	"[BUSINESS DETAIL] GET_BUSINESS_DAYS_FAILED";

export const ACTIVE_BUSINESS = "[BUSINESS DETAIL] ACTIVE_BUSINESS";
export const ACTIVE_BUSINESS_SUCCESS =
	"[BUSINESS DETAIL] ACTIVE_BUSINESS_SUCCESS";
export const ACTIVE_BUSINESS_FAILED =
	"[BUSINESS DETAIL] ACTIVE_BUSINESS_FAILED";

export const CREATE_TREASURY_ACCOUNT =
	"[BUSINESS DETAIL] CREATE_TREASURY_ACCOUNT";
export const CREATE_TREASURY_ACCOUNT_SUCCESS =
	"[BUSINESS DETAIL] CREATE_TREASURY_ACCOUNT_SUCCESS";
export const CREATE_TREASURY_ACCOUNT_FAILED =
	"[BUSINESS DETAIL] CREATE_TREASURY_ACCOUNT_FAILED";

export const BLOCK_TREASURY_ACCOUNT =
	"[BUSINESS DETAIL] BLOCK_TREASURY_ACCOUNT";
export const BLOCK_TREASURY_ACCOUNT_SUCCESS =
	"[BUSINESS DETAIL] BLOCK_TREASURY_ACCOUNT_SUCCESS";
export const BLOCK_TREASURY_ACCOUNT_FAILED =
	"[BUSINESS DETAIL] BLOCK_TREASURY_ACCOUNT_FAILED";

export const ADD_BUSINESS_TO_CATEGORY =
	"[BUSINESS DETAIL] ADD_BUSINESS_TO_CATEGORY";
export const ADD_BUSINESS_TO_CATEGORY_SUCCESS =
	"[BUSINESS DETAIL] ADD_BUSINESS_TO_CATEGORY_SUCCESS";
export const ADD_BUSINESS_TO_CATEGORY_FAILED =
	"[BUSINESS DETAIL] ADD_BUSINESS_TO_CATEGORY_FAILED";

export const REMOVE_BUSINESS_FROM_CATEGORY =
	"[BUSINESS DETAIL] REMOVE_BUSINESS_FROM_CATEGORY";
export const REMOVE_BUSINESS_FROM_CATEGORY_SUCCESS =
	"[BUSINESS DETAIL] REMOVE_BUSINESS_FROM_CATEGORY_SUCCESS";
export const REMOVE_BUSINESS_FROM_CATEGORY_FAILED =
	"[BUSINESS DETAIL] REMOVE_BUSINESS_FROM_CATEGORY_FAILED";

export const BUSINESS_ADD_USER = "[BUSINESS DETAIL] BUSINESS_ADD_USER";
export const BUSINESS_ADD_USER_SUCCESS =
	"[BUSINESS DETAIL] BUSINESS_ADD_USER_SUCCESS";
export const BUSINESS_ADD_USER_FAILED =
	"[BUSINESS DETAIL] BUSINESS_ADD_USER_FAILED";

export const EDIT_BUSINESS_CONFIG = "[BUSINESS DETAIL] EDIT_BUSINESS_CONFIG";
export const EDIT_BUSINESS_CONFIG_SUCCESS =
	"[BUSINESS DETAIL] EDIT_BUSINESS_CONFIG_SUCCESS";
export const EDIT_BUSINESS_CONFIG_FAILED =
	"[BUSINESS DETAIL] EDIT_BUSINESS_CONFIG_FAILED";

export const UPDATE_BUSINESS_DAYS = "[BUSINESS DETAIL] UPDATE_BUSINESS_DAYS";
export const UPDATE_BUSINESS_DAYS_SUCCESS =
	"[BUSINESS DETAIL] UPDATE_BUSINESS_DAYS_SUCCESS";
export const UPDATE_BUSINESS_DAYS_FAILED =
	"[BUSINESS DETAIL] UPDATE_BUSINESS_DAYS_FAILED";

export const IMPORT_USERS = "[BUSINESS DETAIL] IMPORT_USERS";
export const IMPORT_USERS_SUCCESS = "[BUSINESS DETAIL] IMPORT_USERS_SUCCESS";
export const IMPORT_USERS_FAILED = "[BUSINESS DETAIL] IMPORT_USERS_FAILED";

export const EXPORT_CONSUMPTION = "[BUSINESS DETAIL] EXPORT_CONSUMPTION";
export const EXPORT_CONSUMPTION_SUCCESS =
	"[BUSINESS DETAIL] EXPORT_CONSUMPTION_SUCCESS";
export const EXPORT_CONSUMPTION_FAILED =
	"[BUSINESS DETAIL] EXPORT_CONSUMPTION_FAILED";
export const EXPORT_INTEGRATION_HCM_FAILED =
	"[BUSINESS DETAIL] EXPORT_INTEGRATION_HCM_FAILED";

export const ADD_EXTRA_PROPERTY = "[BUSINESS EDIT] ADD_EXTRA_PROPERTY";
export const ADD_EXTRA_PROPERTY_SUCCESS =
	"[BUSINESS EDIT] ADD_EXTRA_PROPERTY_SUCCESS";
export const ADD_EXTRA_PROPERTY_FAILED =
	"[BUSINESS EDIT] ADD_EXTRA_PROPERTY_FAILED";

export const GET_EXTRA_PROPERTIES = "[BUSINESS EDIT] GET_EXTRA_PROPERTIES";
export const GET_EXTRA_PROPERTIES_SUCCESS =
	"[BUSINESS EDIT] GET_EXTRA_PROPERTIES_SUCCESS";
export const GET_EXTRA_PROPERTIES_FAILED =
	"[BUSINESS EDIT] GET_EXTRA_PROPERTIES_FAILED";

export const HCM_SYNC_DATA = "[BUSINESS EDIT] HCM_SYNC_DATA";
export const HCM_SYNC_DATA_SUCCESS = "[BUSINESS EDIT] HCM_SYNC_DATA_SUCCESS";
export const HCM_SYNC_DATA_FAILED = "[BUSINESS EDIT] HCM_SYNC_DATA_FAILED";

export const HCM_INFO_DATA = "[BUSINESS EDIT] HCM_INFO_DATA";
export const HCM_INFO_DATA_SUCCESS = "[BUSINESS EDIT] HCM_INFO_DATA_SUCCESS";
export const HCM_INFO_DATA_FAILED = "[BUSINESS EDIT] HCM_INFO_DATA_FAILED";

export const SET_VALUE = "[BUSINESS DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[BUSINESS DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getBusiness(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESS });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/Business/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_BUSINESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export const ADD_BRANCH = "[BUSINESS DETAIL] ADD_BRANCH";
export const ADD_BRANCH_SUCCESS = "[BUSINESS DETAIL] ADD_BRANCH_SUCCESS";
export const ADD_BRANCH_FAILED = "[BUSINESS DETAIL] ADD_BRANCH_FAILED";

export function addBranch(headquartersId, branchId, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: ADD_BRANCH });

		try {
			const { login } = getState();

			const response = await api.sendPut(
				`/Business/${branchId}/headquarters?headquartersId=${headquartersId}`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ADD_BRANCH_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: ADD_BRANCH_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: ADD_BRANCH_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export const ADD_FRANCHISE_BUSINESS =
	"[BUSINESS DETAIL] ADD_FRANCHISE_BUSINESS";
export const ADD_FRANCHISE_BUSINESS_SUCCESS =
	"[BUSINESS DETAIL] ADD_FRANCHISE_BUSINESS_SUCCESS";
export const ADD_FRANCHISE_BUSINESS_FAILED =
	"[BUSINESS DETAIL] ADD_FRANCHISE_BUSINESS_FAILED";

export function addFranchiseBusiness(franchiseId, id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: ADD_FRANCHISE_BUSINESS });

		try {
			const { login } = getState();

			const response = await api.sendPut(
				`/Business/${id}/Franchise?franchiseId=${franchiseId}`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ADD_FRANCHISE_BUSINESS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: ADD_FRANCHISE_BUSINESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: ADD_FRANCHISE_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getBusinessDays(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESS_DAYS });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/Business/${id}/Days`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_DAYS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_BUSINESS_DAYS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_BUSINESS_DAYS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export const HCMPartial =
	(businessId, cpfs, callback) => async (dispatch, getState) => {
		dispatch({ type: HCM_SYNC_DATA });

		try {
			const { login } = getState();

			const cpfsClean = cpfs.map(i => ({ document: i.document }));

			const response = await api.sendPost(
				`/Business/${businessId}/Hcm/Pilot`,
				cpfsClean,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (response.status === 200) {
				dispatch({
					type: HCM_SYNC_DATA_SUCCESS,
					payload: null
				});

				return callback && callback(null, null);
			}

			if (response.status === 404) {
				dispatch({
					type: HCM_SYNC_DATA_FAILED,
					payload: "Aconteceu um erro"
				});

				return callback && callback("Aconteceu um erro");
			}
			const errors = getErrors(
				"Error no servidor, aguarde um momento e tente mais tarde"
			);

			dispatch({
				type: HCM_SYNC_DATA_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: HCM_SYNC_DATA_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};

export function HCMSync(businessID, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: HCM_SYNC_DATA });

		try {
			const { login } = getState();

			const response = await api.sendPost(
				`/Business/${businessID}/Sync`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: HCM_SYNC_DATA_SUCCESS,
					payload: result.model
				});

				modalAlert({
					title: "Processo de Sincronização",
					body: "Esse processo pode levar aproximadamente 2 horas"
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: HCM_SYNC_DATA_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: HCM_SYNC_DATA_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function LastSyncInfo(businessID, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: HCM_INFO_DATA });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Business/${businessID}/LastSyncInfo`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result) {
				dispatch({
					type: HCM_INFO_DATA_SUCCESS,
					payload: result
				});
				callback && callback(result);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: HCM_INFO_DATA_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: HCM_INFO_DATA_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function addBusinessToCategory(id, categoryId, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: ADD_BUSINESS_TO_CATEGORY });

		try {
			const { login } = getState();

			const response = await api.sendPost(
				`/Business/${id}/Category/${categoryId}`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (response.ok) {
				dispatch({
					type: ADD_BUSINESS_TO_CATEGORY_SUCCESS,
					payload: categoryId
				});

				return dispatch(getBusiness(id, callback));
			}

			let result = await response.json();

			const errors = getErrors(result.errors);

			dispatch({
				type: ADD_BUSINESS_TO_CATEGORY_FAILED,
				payload: errors
			});

			callback && callback(errors);
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: ADD_BUSINESS_TO_CATEGORY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function removeBusinessFromCategory(id, categoryId, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: REMOVE_BUSINESS_FROM_CATEGORY });

		try {
			const { login } = getState();

			const response = await api.sendDelete(
				`/Business/${id}/Category/${categoryId}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (response.ok) {
				dispatch({
					type: REMOVE_BUSINESS_FROM_CATEGORY_SUCCESS,
					payload: categoryId
				});

				return dispatch(getBusiness(id, callback));
			}

			let result = await response.json();

			const errors = getErrors(result.errors);

			dispatch({
				type: REMOVE_BUSINESS_FROM_CATEGORY_FAILED,
				payload: errors
			});

			callback && callback(errors);
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: REMOVE_BUSINESS_FROM_CATEGORY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function addUserToBusiness(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: BUSINESS_ADD_USER });

		try {
			const { login } = getState();

			const response = await api.sendPost(`/Business/${id}/User`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: BUSINESS_ADD_USER_SUCCESS,
					payload: result.model
				});

				dispatch(getBusiness(id, callback));
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: BUSINESS_ADD_USER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: BUSINESS_ADD_USER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createTreasuryAccount(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_TREASURY_ACCOUNT });

		try {
			const { login } = getState();

			const response = await api.sendPost(
				`/Business/${id}/TreasuryAccount`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_TREASURY_ACCOUNT_SUCCESS,
					payload: result.model
				});

				dispatch(getBusiness(id, callback));
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_TREASURY_ACCOUNT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: CREATE_TREASURY_ACCOUNT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getBalanceUsers(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: BLOCK_TREASURY_ACCOUNT });

		try {
			const { login } = getState();

			const response = await api.sendPost(
				`/export/${id}/business-user-balances`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = response;

			if (result.status === 200) {
				dispatch({
					type: BLOCK_TREASURY_ACCOUNT_SUCCESS,
					payload: null
				});

				dispatch(getBusiness(id, callback));
			} else {
				dispatch({
					type: BLOCK_TREASURY_ACCOUNT_FAILED,
					payload: null
				});
				callback && callback("Ocorreu um erro ao enviar o relatório");
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: BLOCK_TREASURY_ACCOUNT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getTreasuryAccountBalance(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: BLOCK_TREASURY_ACCOUNT });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Business/${id}/TreasuryAccountBalance`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: BLOCK_TREASURY_ACCOUNT_SUCCESS,
					payload: result.model
				});

				dispatch(getBusiness(id, callback));
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: BLOCK_TREASURY_ACCOUNT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: BLOCK_TREASURY_ACCOUNT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function blockTreasuryAccount(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: BLOCK_TREASURY_ACCOUNT });

		try {
			const { login } = getState();

			const response = await api.sendPost(
				`/Business/${id}/TreasuryAccount?status=blocked`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: BLOCK_TREASURY_ACCOUNT_SUCCESS,
					payload: result.model
				});

				dispatch(getBusiness(id, callback));
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: BLOCK_TREASURY_ACCOUNT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: BLOCK_TREASURY_ACCOUNT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function activeBusiness(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: ACTIVE_BUSINESS });

		try {
			const { login } = getState();

			const response = await api.sendPut(`/Business/${id}/Active`, null, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ACTIVE_BUSINESS_SUCCESS,
					payload: result.model
				});

				dispatch(getBusiness(id, callback));
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: ACTIVE_BUSINESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: ACTIVE_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editConfig(model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_BUSINESS_CONFIG });

		try {
			const { login, businessDetail } = getState();

			const response = await api.sendPut(
				`/Business/${businessDetail.item.id}/Config`,
				model,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_BUSINESS_CONFIG_SUCCESS,
					payload: result.model
				});

				dispatch(getBusiness(businessDetail.item.id, callback));
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_BUSINESS_CONFIG_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EDIT_BUSINESS_CONFIG_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editBusinessDays(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_BUSINESS_DAYS });

		try {
			const { login, businessDetail } = getState();

			const { item, days } = businessDetail;

			const response = await api.sendPost(`/Business/${item.id}/Days`, days, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_BUSINESS_DAYS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_BUSINESS_DAYS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_BUSINESS_DAYS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function exportConsumption(
	id,
	from,
	to,
	unifiedOfGroup,
	recharge,
	callback
) {
	return async (dispatch, getState) => {
		dispatch({ type: EXPORT_CONSUMPTION });

		try {
			const { login } = getState();

			if (from.format) from = from.format("YYYY-MM-DD");
			if (to.format) to = to.format("YYYY-MM-DD");

			const response = recharge
				? await api.sendGet(`/Business/${id}/AverageRechargeReport`, {
						Authorization: `Bearer ${login.token}`
				  })
				: await api.sendPost(
						`/Export/${id}/Consumption?from=${from}&to=${to}&unified=${unifiedOfGroup}`,
						null,
						{
							Authorization: `Bearer ${login.token}`
						}
				  );

			let result = recharge ? response : await response.json();

			if (result.isValid || result.status === 200) {
				dispatch({
					type: EXPORT_CONSUMPTION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EXPORT_CONSUMPTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EXPORT_CONSUMPTION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function importUsers(data, callback, hasWiipoClub) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_USERS });

		try {
			const { login, businessDetail } = getState();

			let model = data.map(
				({
					cpf,
					enroll,
					name,
					lastName,
					email,
					phoneNumber,
					limit,
					admissionDate,
					costCenterCode,
					birthDate,
					mothersName,
					zipCode,
					street,
					number,
					complement,
					neighborhood,
					city,
					state,
					country
				}) => ({
					cpf: String(cpf)?.match(/\d/g)?.join("").padStart(11, "0"),
					enroll,
					name,
					lastName,
					email,
					phoneNumber: phoneNumber?.match(/\d/g)?.join(""),
					creditLimit: {
						limit: limit && hasWiipoClub ? limit.match(/\d/g).join("") : 0
					},
					status:
						admissionDate &&
						dayjs(admissionDate, "DD/MM/YYYY").isValid() &&
						dayjs(admissionDate, "DD/MM/YYYY").isAfter(dayjs(new Date()))
							? 0
							: 1,
					mothersName,
					admissionDate:
						admissionDate && dayjs(admissionDate, "DD/MM/YYYY").isValid()
							? dayjs(admissionDate, "DD/MM/YYYY").toDate()
							: null,
					birthDate:
						birthDate && dayjs(birthDate, "DD/MM/YYYY").isValid()
							? dayjs(birthDate, "DD/MM/YYYY").toDate()
							: null,
					address:
						city && state
							? {
									street,
									number,
									complement,
									neighborhood,
									city,
									state,
									country: country || "BR",
									zipCode: zipCode?.match(/\d/g).join("")
							  }
							: undefined,
					costCenterCode
				})
			);

			const chunks = _.chunk(model, 100);

			let result = { model: [], errors: {}, isValid: true };

			for (const chunk of chunks) {
				const response = await api.sendPost(
					`/Business/${businessDetail.item.id}/User/Import`,
					chunk,
					{
						Authorization: `Bearer ${login.token}`
					}
				);

				let result2 = await response.json();

				if (result2.model) result.model = [...result.model, ...result2.model];

				if (result2.errors)
					result.errors = { ...result.errors, ...result2.errors };

				if (result.isValid) result.isValid = result2.isValid;
			}

			if (result.isValid) {
				dispatch({
					type: IMPORT_USERS_SUCCESS,
					payload: result.model
				});

				callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_USERS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: IMPORT_USERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getExtraProperties(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_EXTRA_PROPERTIES });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/Business/${id}/Property`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_EXTRA_PROPERTIES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_EXTRA_PROPERTIES_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_EXTRA_PROPERTIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function addExtraProperty(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: ADD_EXTRA_PROPERTY });

		try {
			const { login } = getState();

			const response = await api.sendPost(`/Business/${id}/Property`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ADD_EXTRA_PROPERTY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: ADD_EXTRA_PROPERTY_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: ADD_EXTRA_PROPERTY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export const downloadIntegrationLog =
	(id: string) => async (dispatch, getState) => {
		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Business/${id}/User/Import/Download/Excel`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			const result = await response.json();

			let link = document.createElement("a"); // Create <a>
			link.href = `data:${result.type};base64,${result.content}`;
			link.download = result.filename;
			link.click();
		} catch (error) {
			return dispatch({
				type: EXPORT_INTEGRATION_HCM_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
