const strings = {
	all: "Todos",
	appName: "Wiipo",
	delete: "Excluir",
	deleted: "Excluído",
	about: "Sobre",
	home: "Início",
	queue: "Fila",
	table: "Mesa",
	tables: "Mesas",
	booking: "Reserva",
	reports: "Relatórios",
	queues: "Filas",
	features: "Recursos",
	download: "Baixar",
	places: "Lugares",
	contact: "Contato",
	description: "Descrição",
	new: "Novo",
	called: "Chamado",
	done: "Finalizado",
	canceled: "Cancelado",
	yes: "Sim",
	no: "Não",
	name: "Nome",
	email: "E-mail",
	cpf: "CPF",
	cpfcnpj: "CPF ou CNPJ",
	emailPlaceholder: "joaosilva@gmail.com",
	namePlaceholder: "João",
	lastName: "Sobrenome",
	lastNamePlaceholder: "Silva",
	pendings: "Pendentes",
	pending: "Pendente",
	schedule: "Agendado",
	toSchedule: "Agendar",
	scheduleFor: "Agendar para",
	unifiqueNF: "Unificar cobrança e nota",
	sendAt: "Enviar em",
	dateRecharge: "Data da recarga",
	completed: "Completo",
	activated: "Ativado",
	temporarilyDisabled: "Desativado temporariamente",
	disabled: "Desativado",
	active: "Ativo",
	activate: "Ativar",
	activateBag: "Ativar Bolso",
	blocked: "Bloqueado",
	confirm: "Confirmar",
	reject: "Rejeitar",
	confirmed: "Confirmado",
	rejected: "Rejeitado",
	calleds: "Chamadas",
	subject: "Assunto",
	sendMessage: "Enviar",
	sendMessageSuccess: "Sucesso! Sua mensagem foi enviada",
	sendMessageError:
		"Oops! Ocorreu um erro ao tentar enviar sua mensagem, tente novamente",
	errorTitle: "Error",
	errorMsgGeneric:
		"Oops! Ocorreu um erro ao tentar enviar sua solicitação, tente novamente",
	error404: "Oops! Ocorreu um erro em nossos servidores",
	errorInsuficientFoundHeadquarters:
		"Saldo na conta tesouraria da matriz é insuficiente para a recarga",
	successTitle: "Sucesso!",
	successMsgGeneric: "Processo executado com sucesso",
	executed: "Executar",
	fileNotSended: "Arquivo não foi enviado",
	signUp: "Cadastrar",
	signIn: "Entrar",
	logout: "Sair",
	loginIn: "Entrar em",
	forgotPassword: "Esqueceu sua Senha",
	dontHaveAnAccount: "Não tem uma conta",
	required: "Obrigatório",
	linkInvalid: "Link inválido",
	clear: "Limpar",
	nextPassword: "Próxima senha",
	emailNotValid: "E-mail inválido",
	emptyEmail: "E-mail não preenchido",
	emptyNumber: "Número não preenchido",
	emptyNeighborhood: "Bairro não preenchido",
	emptyCity: "Cidade não preenchida",
	emptyStreet: "Rua não preenchida",
	"Invalid password.": "Senha incorreta",
	number: "Número",
	invalid: "Inválido",
	invalidCaracteres: "Valor inválido contem caractere especial ex: / . ~` $ -",
	qrCode: "QR Code",
	connect: "Conectar",
	waitingTime: "Tempo de espera",
	minutes: "Minutos",
	people: "Pessoas",
	resetPassword: "Zerar senha",
	phoneNumber: "Telefone",
	phoneNumberPlaceholder: "+55 12 93456 7890",
	activationDate: "Ativado em: ",
	firstActivationDate: "Ativação do cartão em",
	lastActivationDate: "Atualização do cartão em",
	status: "Status",
	service: "Tipo de Serviço",
	externalId: "ID Externo",
	statusUpdate: "Atualização",
	kycStatus: "Status KYC",
	processing: "Processando",
	inProcessing: "Em processamento",
	process: "Processar",
	awaitingPayment: "Aguardando pagamento",
	processingPayment: "Processando pagamento",
	client: "Cliente",
	areYouSure: "Você tem certeza",
	dateFormat: "DD/MM/YYYY - HH:mm:ss",
	dateFormatWithoutSeconds: "DD/MM/YYYY - HH:mm",
	dateFormatWithoutYearAndSeconds: "DD MMM - HH:mm",
	dateFormatShort: "DD/MM/YYYY",
	dateFormatMonth: "MM/YYYY",
	timeFormat: "HH:mm",
	yesCancel: "Sim, cancelar!",
	cancel: "Cancelar",
	excluded: "Excluir",
	conclude: "Concluir",
	finish: "Finalizar",
	list: "Lista",
	backToLogin: "Voltar para login",
	register: "Cadastrar",
	recoverPassword: "Recuperar a senha",
	newPasswordSentForEmail: "Uma nova senha foi enviada para o e-mail informado",
	userData: "Dados do usuário",
	transactionData: "Dados da transação",
	businessData: "Dados do estabelecimento",
	businessName: "Razão Social",
	business: "Empresa",
	socialName: "Nome fantasia",
	search: "Pesquisar",
	cnpj: "CNPJ",
	zipCode: "CEP",
	street: "Endereço",
	complement: "Complemento",
	neighborhood: "Bairro",
	city: "Cidade",
	state: "Estado",
	country: "País",
	save: "Salvar",
	phoneEmpty: "Telefone não preenchido",
	invalidPhoneNumber: "Telefone inválido",
	invalidCnpj: "CNPJ inválido",
	invalidCpf: "CPF inválido",
	schedules: "Horários",
	open: "Aberto",
	closed: "Fechado",
	finished: "Encerrado",
	limitPerUser: "Limite por usuário",
	actions: "Ações",
	action: "Ação",
	close: "Fechar",
	dateTime: "Data e hora",
	info: "Informações",
	privateInfo: "Informações privadas",
	person: "Pessoa",
	today: "Hoje",
	tomorrow: "Amanhã",
	otherDays: "Outros dias",
	time: "Horário",
	businesses: "Estabelecimentos",
	nameOfBusiness: "Nome do estabelecimento",
	edit: "Editar",
	oops: "Oops",
	address: "Endereço",
	visible: "Visível",
	businessCategories: "Categorias de estabelecimentos",
	createBusinessCategory: "Criar categoria de estabelecimento",
	editBusinessCategory: "Editar categoria de estabelecimento",
	backgroundColor: "Cor de fundo",
	creationDate: "Data criação",
	notDefined: "Não definido",
	categories: "Categorias",
	category: "Categoria",
	economicGroup: "Grupo econômico",
	consultHCM: "Consulta do HCM",
	hasHCMService: "Cliente HCM Senior",
	HCMTotal: "Base total HCM",
	HCMManual: "Selecionar usuários manualmente",
	WiipoRequest: "Wiipo Request - ID Parceiro",
	isPJ: "Pessoa Jurídica",
	WiipoRequestToken: "Wiipo Request - Token Parceiro",
	loading: "Carregando",
	users: "Usuários",
	addUser: "Adicionar Usuário",
	editUser: "Editar Usuário",
	role: "Função",
	responsible: "Responsável",
	responsibleData: "Dados do Responsável",
	storeAndCompany: "Loja e Empresa",
	store: "Loja",
	storeName: "Nome da Loja",
	storeCnpj: "CNPJ da Loja",
	company: "Empresa",
	type: "Tipo",
	undefined: "Não definido",
	Admin: "Administrador",
	"Business Admin": "Administrador da empresa",
	"Admin Wiipo Flex": "Administrador Wiipo Flex",
	wiipoflex: "Wiipo Flex",
	clubewiipo: "Clube Wiipo",
	employee: "Funcionário",
	Employee: "Funcionário",
	User: "Usuário",
	none: "Nenhum",
	noneSelectedText: "Selecione uma opção",
	importedBy: "Tipo de importação",
	benefits: "Benefícios",
	refreshLimit: "Atualizar limite",
	limit: "Limite",
	limitClubeWiipo: "Limite Clube Wiipo",
	reservedLimit: "Limite reservado",
	renewalDay: "Dia renovação",
	editBenefits: "Editar benefícios",
	fee: "Taxa",
	valueTransferViaVarejo: "Repasse Via Varejo",
	discount: "Desconto",
	color: "Cor",
	admFee: "Taxa ADM",
	paymentDay: "Dia de pagamento",
	rolePlaceholder: "Cargo do funcionário",
	fileSizeIsTooBig: "Arquivo muito grande",
	companies: "Empresas",
	stores: "Lojas",
	storeData: "Dados da loja",
	companyData: "Dados da empresa",
	transactions: "Transações",
	value: "Valor",
	processed: "Processado",
	valueProcessed: "Valor Processado",
	valueToRecharge: "Valor Pedido",
	totalValue: "Valor Total",
	finalValue: "Valor Final",
	code: "Código",
	cnpjRelaction: "CNPJs relacionados",
	id: "Id",
	idNF: "ID Nota fatura",
	pendingApproval: "Pendente",
	partialApproved: "Parcialmente",
	approved: "Aprovada",
	reproved: "Reprovada",
	inAnalysis: "Em análise",
	inManualAnalysis: "Em análise manual",
	refunded: "Reembolsado",
	refused: "Recusada",
	descriptionOptional: "Descrição (opcional)",
	installmentsOptional: "Parcelas (opcional)",
	installments: "Parcelas",
	installment: "Parcela",
	insertCNPJ: "Insira o CNPJ para a cobrança única:",
	changePassword: "Alterar senha",
	password: "Senha",
	confirmPassword: "Confirme a senha",
	confirmNewPassword: "Confirmar a nova senha",
	oldPassword: "Senha antiga",
	newPassword: "Nova senha",
	insertOldPassword: "Insira sua senha antiga",
	insertNewPassword: "Insira sua nova senha",
	insertConfirmNewPassword: "Confirme sua nova senha",
	notMatch: "Não coincide",
	paid: "Pago",
	charges: "Cobranças",
	main: "Principal",
	split: "Divisão",
	splits: "Divisões",
	editTransaction: "Editar transação",
	addTransaction: "Adicionar transação",
	addCharge: "Adicionar Cobrança",
	userPlaceholder: "Selecione um usuário",
	user: "Usuário",
	storePlaceholder: "Selecione uma loja",
	credits: "Créditos",
	addCredit: "Adicionar crédito",
	companyPlaceholder: "Selecione uma empresa",
	creditData: "Dados do crédito",
	uses: "Usos",
	editCredit: "Editar crédito",
	paymentType: "Tipo de pagamento",
	payer: "Pagador",
	payroll: "Folha de pagamento",
	credit: "Crédito",
	debt: "Débito",
	creditBetweenTreasuries: "Entrada Tesouraria",
	debitBetweenTreasuries: "Saída Tesouraria",
	minLength: "Informe no mínimo {0} caracteres",
	cashier: "Caixa",
	Cashier: "Caixa",
	icon: "Ícone",
	iconSource: "Fonte do ícone",
	isVisible: "Visível",
	notNumber: "Sem número",
	highlight: "Destacar",
	national: "Nacional",
	teaser: "Teaser",
	limitUsed: "Limite usado",
	remainingLimit: "Limite restante",
	remainingCredit: "Crédito restante",
	extraProperties: "Propriedades extras",
	add: "Adicionar",
	ifood_code: "Código - Ifood",
	ifood_shortcode: "Nº Pedido - Ifood",
	ifood_place: "Estabelecimento - Ifood",
	ifood_occasion: "Ocasião - Ifood",
	ifood_value: "Valor - Ifood",
	ifood_fee: "Taxa - Ifood",
	uber_ride_code: "Código da Corrida - Uber",
	uber_ride_value: "Valor da Corrida - Uber",
	uber_ride_fee: "Taxa da Corrida - Uber",
	uber_code: "Código - Uber",
	uber_shortcode: "Nº pedido - Uber",
	uber_place: "Estabelecimento - Uber",
	uber_service: "Serviço - Uber",
	uber_value: "Valor - Uber",
	uber_distance: "Distancia - Uber",
	uber_duration: "Duração - Uber",
	uber_city: "Cidade - Uber",
	uber_fee: "Taxa - Uber",
	import: "Importar",
	importUsers: "Importar usuários",
	importTransactions: "Importar transações",
	maxInstallment: "Max. Parcela",
	sale: "Venda",
	purchaseDate: "Data compra",
	hasLogin: "Tem acesso",
	newConsumptionTemplate: "Template novo consumo",
	configuration: "Configurações",
	types: "Tipos",
	icons: "Icones",
	createIcon: "Criar icone",
	formBalanceAccount: "Cadastro de BalanceAccount",
	requestNewCards: "Solicitar Novos Cartões",
	selectIcon: "Selecionar icone",
	selectBanner: "Selecionar banner",
	editConfiguration: "Editar configurações",
	export: "Exportar",
	exportConsumption: "Exportar consumos",
	from: "De",
	to: "Até",
	exports: "Exportações",
	send: "Enviar",
	sendEmail: "Enviar e-mail",
	includeIntegrationFile: "Incluir arquivo integração",
	enroll: "Matricula",
	consumptionReportTemplate: "Template relatório consumo",
	boletoUrl: "Link do boleto",
	total: "Total",
	transfer: "Transferir",
	transfers: "Transferências",
	transferSucessBrancher: "Saldo depositado nas filiais com sucesso",
	transferSucessPartialBrancher:
		"Saldo depositado nas filiais de forma parcial. Verificar erros",
	treasuryAccountBalance: "Saldo atual",
	treasury: "Tesouraria",
	invoice: "Faturas",
	pendingTransfersValue: "Transferências pendentes",
	totalRequiredValue: "Saldo necessário",
	valueSubtract: "Valor à transferir",
	checkoutTemplate: "Template checkout",
	generateCheckout: "Gerar checkout",
	checkouts: "Checkouts",
	report: "Relatório",
	created: "Criado",
	createdRequest: "Criação de pedido",
	failToCreate: "Falha ao criar",
	updateRequest: "Atualizado o status",
	updated: "Atualizado",
	updatedFunds: "Em atualização",
	failToUpdate: "Falhar ao atualizar",
	createdBy: "Criado por",
	updatedBy: "Atualizado por",
	lastPeriod: "Último período",
	allowances: "Subsídios",
	allowancePoliciesActive: "Políticas de subsídio ativas",
	allowancePolicies: "Políticas de subsídio",
	limitType: "Tipo do limite",
	contractType: "Tipo de contrato",
	activeUsers: "Usuários Ativos",
	contractedLives: "Vidas Contratadas",
	usersWithRecharge: "Usuários com Recarga",
	money: "Dinheiro",
	percentage: "Porcentagem",
	percentageValue: "Porcentagem/Valor",
	editAllowance: "Editar subsídio",
	createAllowance: "Criar subsídio",
	DuplicateUserName: "Já existe um usuário com este documento",
	ErrorLimitClube: "Houve um erro ao criar o Limite",
	ErrorUserNotFound: "Usuário não encontrado",
	ErrorUserMothersNameNotFound: "Nome da mãe não encontrado",
	ErrorUserBirthdateNotFound: "Data de nascimento não encontrado",
	emissionDate: "Data de Emissão",
	ErrorUserBirthdateOrMothersNameNotFound:
		"Data de nascimento e/ou Nome da mãe não encontrado",
	ErrorUserDesconhecido: "Erro desconhecido",
	ErrorCnpjNotFound: "CNPJ não encontrado no banco de dados",
	ErrorCpfNotFound: "CPF não encontrado no banco de dados",
	maxLimit: "Limite máximo",
	daily: "Diariamente",
	weekly: "Semanal",
	createPromotion: "Criar Promoção",
	biweekly: "Quinzenal",
	monthly: "Mensal",
	start: "Início",
	end: "Fim",
	renewalCycle: "Renovação",
	sunday: "Domingo",
	monday: "Segunda",
	tuesday: "Terça",
	wednesday: "Quarta",
	thursday: "Quinta",
	friday: "Sexta",
	saturday: "Sábado",
	editAllowanceTime: "Editar horários",
	editAllowanceUsers: "Editar usuários",
	editAllowanceStores: "Editar lojas",
	editConsumptionPolicyTime: "Editar horários",
	editUsers: "Editar usuários",
	editCategories: "Editar categorias",
	editStores: "Editar lojas",
	allowance: "Subsídio",
	order: "Pedido",
	price: "Preço",
	minPrice: "Preço mínimo",
	product: "Produto",
	products: "Produtos",
	quantity: "Quantidade",
	voucherP: "Gerar voucher no parceiro",
	voucherUnit: "Gerar vendas individuais",
	freeShipping: "Frete grátis",
	productCategories: "Categorias de produto",
	createProduct: "Criar produto",
	editProduct: "Editar produto",
	editAccession: "Editar adesão",
	createProductCategory: "Criar categoria de produto",
	editProductCategory: "Editar categoria de produto",
	importProducts: "Importar produtos",
	activity: "Atividade",
	activeOn: "Ativo em",
	activeOnSale: "Ativo na venda",
	createCashbackPolicy: "Criar política de cashback",
	creditMinValue: "Valor mínimo - Crédito",
	creditBusiness: "Empresa - Crédito",
	maxInvestment: "Investimento máximo",
	cashbackPolicy: "Política de Cashback",
	createConsumptionPolicy: "Criar política de consumo",
	editConsumptionPolicy: "Editar política de consumo",
	consumptionPolicyPolicies: "Políticas de consumo",
	expended: "Gasto",
	addPromotion: "Adicionar promoção",
	sending: "Enviando",
	promotions: "Promoções",
	startAt: "Inicia em",
	endAt: "Encerra em",
	emailMessage: "Corpo do e-mail",
	emailSubject: "Assunto do e-mail",
	numberOrder: "Número Ordem de compra",
	numberContract: "Número do Contrato",
	smsMessage: "Corpo da Mensagem SMS",
	whatsAppMessage: "Corpo da Mensagem WhatsApp",
	pushTitle: "Titulo da notificação",
	pushMessage: "Mensagem da notificação",
	filter: "Filtro",
	openStore: "Abrir loja",
	openExternalBrowser: "Abrir link no navegador",
	openInternalBrowser: "Abrir link no APP",
	sent: "Enviado",
	received: "Recebido",
	read: "Lido",
	failed: "Falhado",
	noMessage: "Sem mensagem",
	noSale: "Sem venda",
	noPhone: "Sem telefone",
	ignored: "Ignorado",
	economicMode: "Modo econômico",
	whatsAppRestrict: "Enviar somente para usuários com vendas",
	used: "Usado",
	reportTemplate: "Template Relatório",
	integrationApi: "Api de integração",
	editIntegration: "Editar integração",
	txtFile: "Arquivo TXT",
	jsonFile: "Arquivo JSON",
	payrollDebitPostings: "Lançamentos débito em folha",
	object: "Objeto",
	format: "Formato",
	url: "URL",
	fullName: "Nome",
	document: "Documento",
	custom: "Customizada",
	size: "Tamanho",
	decimal: "Decimal",
	customValue: "Valor",
	emailTo: "E-mail(s) - Separados por ponto e virgula(;)",
	seniorSistemas: "SENIOR SISTEMAS S/A",
	protheusTotvs: "PROTHEUS TOTVS",
	datasulHcm: "DATASUL HCM",
	questorSistemas: "QUESTOR SISTEMAS LTDA",
	dominioSistemas: "DOMINIO SISTEMAS LTDA",
	conveniaRh: "CONVENIA RH",
	provider: "Receptor",
	expiration: "Vencido",
	expirationDate: "Expira em",
	expired: "Expirado",
	discountType: "Tipo desconto",
	deliveryAddresses: "Endereços de entrega",
	standardDeliveryTime: "Tempo de entrega padrão",
	deliveryValue: "Valor da entrega",
	delivery: "Frete",
	enabled: "Ativo",
	linked: "Vinculado",
	detached: "Desvinculado",
	orders: "Pedidos",
	inTheWay: "À caminho",
	delivered: "Entregue",
	deliveredDate: "Entregue",
	return: "Devolvido",
	requestedReturn: "Em processo de Devolução",
	orderData: "Dados do pedido",
	trackingOrder: "Rastreamento de Pedido",
	tracking: "Rastreio",
	actionsTrackingOrder: "Ações do Rastreamento",
	datePrediction: "Previsão de entrega",
	shippingCompany: "Transportadora",
	accessionUrl: "URL adesão",
	accessionEmail: "E-mail adesão",
	registered: "Cadastrado",
	inactive: "Inativo",
	accession: "Adesão",
	accessions: "Adesões",
	remove: "Remover",
	headquarters: "Matriz",
	headquartersData: "Dados da matriz",
	addBranch: "Adicionar filial",
	branches: "Filiais",
	openProduct: "Abrir produto",
	editInstallment: "Editar parcela",
	dynamicDateStart: "Configurar ativação do crédito",
	dynamicDateEnd: "Configurar expiração do crédito",
	validateDaysDynamicDate: "Validade em dias",
	endDateType: "Data de validade",
	dueDate: "Data de vencimento",
	isDigital: "É digital",
	homeOffers: "Ofertas da Home",
	linkBanner: "Link da oferta:",
	showQrCode: "Mostrar QR Code",
	digitalProvider: "Fornecedor",
	ePay: "ePay",
	"ePay Recharge": "ePay Recarga",
	GNC: "GNC",
	digitalProviderCode: "Código do produto no fornecedor",
	digitalUseInstructions: "Instruções de uso",
	digitalUrl: "URL para direcionar após a compra",
	tos: "Termos e condições",
	renewalDayM: "Dia renovação mensal",
	others: "Outros",
	convenienceFees: "Taxas de conveniência",
	createConvenienceFee: "Criar taxa de conveniência",
	editConvenienceFee: "Editar taxa de conveniência",
	minValue: "Valor mínimo",
	maxValue: "Valor máximo",
	createAccession: "Criar adesão",
	"The user already has accession in the store.":
		"O usuário já possui cadastro na loja.",
	whatsApp: "WhatsApp",
	cancelTime: "Tempo cancelamento em minutos",
	quantityOfLivesOnContract: "Quantidade de vidas no contrato",
	editOrder: "Editar pedido",
	Available: "Disponível",
	Sold: "Vendido",
	Unavailable: "Não disponível",
	internal: "Interno",
	card: "Cartão",
	cards: "Cartões",
	creditCard: "Cartão de crédito",
	virtualCards: "Cartões virtuais",
	createVirtualCard: "Criar cartão virtual",
	editVirtualCard: "Editar cartão virtual",
	admissionDate: "Data de admissão",
	minTimeSinceAdmission: "Min. meses admissão",
	maxTimeSinceAdmission: "Max. meses admissão",
	enabledUsers: "Usuários ativos",
	linkOfIfood: "Link da loja no iFood",
	editCashback: "Editar cashback",
	cashbackPolicyEndAt: "Politica de cashback encerra em",
	cashbackPolicyStartAt: "Politica de cashback inicia em",
	creditStartAt: "Crédito inicia em",
	creditEndAt: "Crédito encerra em",
	costCenter: "Centro de custo",
	cost: "Custo",
	generic: "Boleto Registrado",
	bankBilletFee: "Taxa de pagamento de boleto",
	utilityBill: "Boletos de Serviços Públicos",
	costCenters: "Centros de custo",
	referenceCode: "Código de referência",
	addCostCenter: "Adicionar centro de custo",
	editCostCenter: "Editar centro de custo",
	costCenterPlaceholder: "Nome do centro de custo",
	cashback: "Cashback",
	cashbackValue: "Valor do cashback",
	codeSale: "Código da venda",
	saleValue: "Valor da venda",
	historic: "Histórico",
	newStatus: "Novo status",
	oldStatus: "Status anterior",
	notInformed: "Não informado",
	baseValue: "Valor base",
	LevelUp: "LevelUp",
	hideLocationInfo: "Esconder informações de localização",
	hideContactInfo: "Esconder informações de contato",
	Total: "Total",
	available: "Disponível",
	distributed: "Distribuído",
	businessCode: "Código da empresa",
	Incomm: "Incomm",
	Primepass: "Primepass",
	moderation: "Moderação",
	moderationData: "Dados da moderação",
	requester: "Solicitante",
	denied: "Negada",
	attachments: "Anexos",
	requestAllowance: "Subsídio solicitado",
	authorizedAllowance: "Subsídio autorizado",
	prePaidCard: "Cartão pré-pago",
	businessesAccount: "Conta da empresa",
	syncUsers: "Sincronia de usuários",
	balance: "Saldo",
	treasuryAccountHolder: "Treasury Account Holder",
	fundsTreasuryAccount: "Saldo disponível na tesouraria",
	treasuryAccount: "Treasury Account",
	treasuryAccountPTBR: "Tesouraria",
	treasuryAccountID: "ID Tesouraria",
	treasuryAccountBusiness: "ID Tesouraria",
	treasuryAccountTransfer: "Recarga de cartões",
	// accountHolderTransaction: "Movimentações da Account Holder",
	transferProcessed: "Repasse processado",
	accountHolderTransaction: "Transferências Cartão pré-pago",
	treasuryAccountConfirmationTransferValue:
		"Tem certeza que deseja transferir {0} da conta {1} para conta {2}",
	accountHolderCardChangeStatusConfirmation:
		"Confirma a alteração de status para o cartão {0}",
	treasuryAccountConfirmationCancel:
		"Confirmar o cancelamento da transferência",
	treasuryAccountConfirmationReprocess:
		"Confirmar nova tentativa de recarga para os que falharam",
	for: "para",
	hcmParcial: "Parcial via HCM",
	hcmTotal: "Total via HCM",
	create: "Criar",
	block: "Bloquear",
	franchises: "Franquias",
	franchise: "Franquia",
	franchiseData: "Dados da franquia",
	franchiseBusinesses: "Empresas da franquia",
	addFranchiseBusiness: "Adicionar empresa na franquia",
	code_client_senior: "Cód. cliente senior",
	birthDate: "Data de nascimento",
	mothersName: "Nome da mãe",
	lastValue: "Último (R$)",
	currentValue: "Atual (R$)",
	futureValue: "Futuro (R$)",
	markAsSent: "Marcar como enviado",
	sendOnboarding: "Enviar onboarding",
	accountHolder: "Account Holder",
	balanceAccountGroup: "Balance Account Group",
	bankBillet: "Boleto",
	paymentMethod: "Forma de pagamento",
	paymentDate: "Data de pagamento",
	pendingPayment: "Pagamento pendente",
	pendingConfirmation: "Confirmação pendente",
	rollBacked: "Reembolsado",
	pix: "Pix",
	automatedFundsIn: "Funds In Automatizado",
	automatedAndManualFundsIn: "Funds In Automatizado + Transferência Manual",
	manualFundsIn: "Transferência Manual",
	clubPurchase: "Compra no Clube",
	fundsOut: "Funds Out",
	balanceAccountType: "Balance Account",
	balanceAccountTypes: "Tipos de Balance Accounts",
	importBalanceAccountTransfers:
		"Importar transferências da empresa para os funcionários",
	requiredField: "Campo obrigatório",
	fixedValue: "Valor Fixo",
	cardIssue: "Emissão de cartão",
	secondCard: "2ª via do cartão",
	taxCashWithdrawal: "Taxa de Conveniência de Saque",
	taxTransferBagWithdraw: "Tarifa Transferência Bolso Saque",
	transferBetweenBusines: "Transferência entre empresas",
	limitRescueFee: "Tarifa Resgate Limite Clube",
	cardDelivery: "Entrega de cartão",
	activeAccountCost: "Custo Conta ativa",
	feeTicket: "Tarifa",
	inactivityFee: "Tarifa de Inatividade",
	associatedUser: "Usuário associado",
	reasonCharge: "Motivo da cobrança",
	deposit: "Depósito",
	bodyCreateVoucher:
		"Para Acompanhar a geração dos vouchers entre  na aba de créditos !!",
	attention: "Atenção",
	availabilityVouchers:
		"A disponibilidade de estoque e limite de crédito com o parceiro já foi confirmada?",
	initStock: "Estoque inicial",
	finishStock: "Estoque final",
	createVoucher: "Tem certeza que deseja criar",
	vouchersPartner: "vouchers no parceiro",
	dateCreateNotPermitted: "Data de criação não permitida",
	addModeration: "Adicionar moderação",
	accountTreasury: "Conta tesouraria",
	lastTransfer: "Últimas transferências",
	lastRecharge: "Última recarga",
	firstRecharge: "Primeira recarga",
	lastDeposits: "Últimos depósitos",
	newRequest: "Novo depósito avulso",
	due: "Vencimento",
	generate: "Gerar",
	valueTodayPMA: "Saldo disponível na PMA",
	transactionsSentSuccess: "Transações enviadas com sucesso",
	transactionsSentError: "Erro ao enviar transações",
	newTransaction: "Nova transação",
	newTransactionSucess:
		"Criada com sucesso por favor verifique se está tudo ok",
	balanceAccountTypeReport: "Bolsos de Cartão Pré-Pago",
	taxes: "Taxas",
	authorizeTransfer: "Autorizar transferência",
	destinyTransfer: "Destino da transferência",
	transferRelese: "Transferência liberada",
	configure: "Configurar",
	nextText: "Próximo",
	previousText: "Anterior",
	pageText: "Página",
	ofText: "até",
	rowsText: "linhas",
	sync: "Sincronia",
	HCMSync: "Sincronia HCM",
	manualImport: "Cadastro manual",
	idTransaction: "ID da Transação",
	prePaidTransferId: "prePaidTransferId",
	mcc: "MCC",
	merchantId: "ID do comerciante",
	merchantCity: "Cidade do comerciante",
	merchantName: "Nome do comerciante",
	merchantCountry: "País do comerciante",
	panMasked: "PAN Mascarado",
	acquiringId: "ID da aquisição",
	acquiringCountryCode: "Código do país de aquisição",
	addedTime: "Data",
	balanceAccountId: "ID do saldo da conta",
	msgType: "Tipo do MSG",
	currency: "Moeda",
	availableBalanceBefore: "Saldo Antes da Autorização",
	balanceBefore: "Saldo antes",
	balanceAfter: "Saldo depois",
	cardId: "ID do Cartão",
	billingCurrency: "Moeda de cobrança",
	billingAmountAuthorized: "Valor Autorizado",
	billingAmount: "Valor da Transação",
	treasuryAccountHolderId: "ID do titular da conta da tesouraria",
	txnAmount: "txnAmount",
	secondCardFee: "Segunda via do cartão",
	iof: "iof",
	balanceAccountGroupId: "ID do grupo de contas de saldo",
	saleId: "Id da Venda",
	accountHolderId: "ID do titular da conta",
	prePaidTransfer: "Transferência Pré-paga",
	payeeAccountId: "payeeAccountId",
	payerAccountId: "payerAccountId",
	paymentId: "paymentId",
	userId: "ID do Usuário",
	typeInternal: "typeInternal",
	refundTransactionId: "ID da transação de reembolso",
	spreadsheetImport: "Importação manual",
	manualRegistration: "Cadastro Manual",
	batchRegister: "Cadastro em lote",
	percentClubWiipo: "Limite Clube Wiipo",
	continue: "Continuar",
	singleDeposit: "Depósito avulso",
	linkedToTransfer: "Atrelado a recarga",
	linkType: "Site/Cardápio/Catálogo",
	link: "Link Site/Cardápio/Catálogo",
	ticket: "Pague por boleto",
	chargePersonType: "Cobrança",
	chargeJuridicalPerson: "PJ",
	chargeFisicalPerson: "PF",
	newTransfer: "Nova transferência",
	paymentPix: "Pagamento via PIX",
	wiipoFlexAverageValuePerUser: "Recarga média contratada do flex",
	cnpjSource: "CNPJ Origem",
	typeOfMovent: "Tipo de movimento",
	extract: "Extrato completo PMA",
	extractInvoice: "Extrato da Fatura",
	data: "Data",
	sourceAccount: "Conta Origem",
	source: "ID Origem",
	sourceTreasuryAccount: "Tesouraria Origem",
	destinyAccount: "Conta destino",
	destinyid: "ID Destino",
	destinyCnpj: "CNPJ Destino",
	destinyTreasuryAccount: "Tesouraria Destino",
	motive: "Motivo",
	saleBefore: "Saldo antes",
	saleAfter: "Saldo depois",
	clubForWhoIsEligible: "Clube > Elegíveis",
	clubForWhoAlreadyBought: "Clube > Só quem já comprou",
	clubForWhoNeverBought: "Clube > Nunca comprou ou baixou o app",
	clubForWhoDownloadedNeverBought: "Clube > Baixou o app mas não comprou",
	clubForWhoDownloadedNeverBoughtInTheLast30Days:
		"Clube > Baixou o app mas não comprou há mais de 30 dias",
	clubForWhoDownloadedNeverBoughtInTheLast60Days:
		"Clube > Baixou o app mas não comprou há mais de 60 dias",
	approvedBy: "Aprovado Por",
	canceledBy: "Cancelado Por",
	deniedBy: "Negado Por",
	consumptionLimit: "Limite de Consumo",
	marketing: "Marketing",
	firstPassword: "Cadastre uma senha para o primeiro acesso",
	almost: "Quase lá",
	unifiedOfGroup: "Unificado do grupo",
	userBlocked: "Usuário Temporariamente Bloqueado",
	userBlockedMessage: "Reset a sua senha para efetuar o desbloqueio",
	invoicingClubeWiipo: "Faturamento Clube",
	invoicingClubeWiipoMenu: "Faturamento",
	partnerName: "Parceiro",
	partnerCNPJ: "CNPJ do parceiro",
	usersWhoConsumed: "Usuários que consumiram",
	companiesReached: "Empresas alcançadas",
	sumAmount: "Soma de valor",
	sumSaleValue: "Valor total Venda",
	sumSaleTax: "Soma de Taxa",
	sumTakeRate: "Soma de Take Rate",
	sumTransferAmount: "Valor de Repasse",
	period: "Período",
	accountWithDigit: "Conta com dígito",
	account: "Conta",
	accountDigit: "Dígito",
	dataBankAccount: "Conta bancária",
	bankName: "Instituição Financeira",
	holderName: "Titular",
	agency: "Agência",
	pixKey: "Chave Pix",
	pixType: "Tipo de Chave Pix",
	campaignType: "Tipo da campanha",
	publish: "Publicar",
	campaign: "Campanha",
	campaigns: "Campanhas",
	origin: "Origem do crédito",
	empty: "", // pro input aceitar vazio o titulo
	receivedRequestCard: "Solicitação recebida",
	sendRequestCard: "Pedido enviado para transportadora",
	processedRequestCard: "Pedido em processamento",
	failedRequestCard: "Pedido cancelado",
	vip: "vip",
	plus: "plus",
	onBoarding: "Incluir politica para todos os novos colaboradores",
	removerLockout: "Remover Lockout",
	temporarilyInactivate: "Desativar temporariamente",
	helpiiLimit: "Limite Helpii",
	changePasswordInstructions:
		"Siga as instruções abaixo para atualizar sua senha",
	passwordExpirationDate: "Senha expira em"
};

export default strings;
