import "react-toastify/dist/ReactToastify.min.css";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import Spinner from "Components/Spinner";
import Switch from "Components/Switch";
import TooltipLight from "Components/TooltipLight";
import dayjs from "dayjs";
import { removeDiacritics } from "helpers/string";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import userKycStatus from "models/userKycStatus";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { FaFileExport, FaFileImport, FaPlus } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getBalanceUsers } from "store/actions/business/detail";
import { getRoles } from "store/actions/role/details";
import { exportUsersData } from "store/actions/user/export";
import { getUsers, sendUsersOnboarding } from "store/actions/user/report";
import Entity from "Types/Entity";
import AddUser from "views/Dashboard/Users/Add";
import ConsultHCM from "views/Dashboard/Users/ConsultHCM";
import XLSX from "xlsx";

import ImportUsers from "../../ImportUsers";
import { extractUsersFromJson } from "./helpers";
import styles from "./styles";

let getUsersDebounced: (() => void) & _.Cancelable = null;

function Users({ setValue, item, history, hasHCMIntegrator }) {
	const inputRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch();

	const state = useSelector<any, any>(s => s.userReport);
	const [loadingMail, setLoadingMail] = useState(false);
	const { loading } = useSelector<any, any>(s => s.userExport);
	const { items: roles } = useSelector<any, any>(s => s.roleDetails);

	const [modalTrash, setModalTrash] = useState<boolean>(false);

	const handleChangeModalTrash = useCallback(() => {
		setModalTrash(!modalTrash);
	}, [modalTrash]);

	const addCheckDataUsers = useMemo(() => {
		const dataUsers = state;
		if (dataUsers.items.length > 0) {
			return dataUsers.items.map(i => ({ ...i, checked: false }));
		}

		return [];
	}, [state]);

	const [showAddUser, setShowAddUser] = useState(false);
	const [importUserData, setImportUserData] = useState(null);
	const [openModalConsultHCM, setOpenModalConsultHCM] = useState(false);

	const handleToast = useCallback(
		(msg: string) => toast.error(`${msg}`, { theme: "colored" }),
		[]
	);

	const handleOpenModalConsultHCM = useCallback(() => {
		setOpenModalConsultHCM(true);
	}, []);

	const handleCloseModalConsultHCM = useCallback(() => {
		setOpenModalConsultHCM(false);
	}, []);

	const [alertComponent, setAlert] = useState(null);
	const [getAllUsers, setGetAllUsers] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const table = useRef(null);

	const loadData = useCallback(() => {
		dispatch(getRoles());

		dispatch(
			getUsers(
				null,
				null,
				`&filters[businessId]=${item.id}&filters[status]=1`,
				null,
				null,
				null,
				null
			)
		);
		setIsLoaded(true);
	}, [dispatch, item.id]);

	const handleImportUser = useCallback(() => {
		inputRef.current.click();
	}, []);

	const handleFilesChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files[0];

			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;
			reader.onload = e => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
				/* Get first worksheet */
				const wsName = wb.SheetNames[0];
				const sheet = wb.Sheets[wsName];

				const ref = XLSX.utils.decode_range(sheet["!ref"]);

				for (let C = ref.s.c; C <= ref.e.c; ++C) {
					const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
					if (cell && cell.t === "s") {
						cell.v = removeDiacritics(cell.v.trim().toLowerCase());
						if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());
					}
				}

				const users = extractUsersFromJson(XLSX.utils.sheet_to_json(sheet, {}));

				setImportUserData(users);
			};

			if (rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);
		},
		[]
	);

	const handleSendOnboarding = useCallback(
		(users: Entity[]) => {
			dispatch(
				sendUsersOnboarding(
					users.map(c => c.id),
					err => {
						if (err) {
							toastError(err);
						} else {
							setAlert(null);
							toastSuccess(
								"Envio da mensagem de onboarding agendado com sucesso"
							);
						}
					}
				)
			);
		},
		[dispatch]
	);

	const sendEmailBalanceUsers = useCallback(() => {
		setLoadingMail(true);
		dispatch(
			getBalanceUsers(item.id, err => {
				if (err) {
					toastError(JSON.stringify(err));
				} else {
					toastSuccess(
						"Foi enviado com sucesso e  chegará no seu email, aguarde um momento..."
					);
				}

				setLoadingMail(false);
			})
		);
	}, [dispatch, item.id]);

	const handleConfirmSendOnboarding = useCallback(
		(users: Entity[]) => {
			setAlert(
				<Dialog
					open
					onClose={() => setAlert(null)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Tem certeza que deseja enviar a notificação de onboarding?
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Após a confirmação uma mensagem de onboarding será enviada ao(s)
							usuário(s) criado(s).
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => handleSendOnboarding(users)}
							color="primary"
							autoFocus
						>
							Confirmar
						</Button>
						<Button onClick={() => setAlert(null)} color="primary">
							Cancelar
						</Button>
					</DialogActions>
				</Dialog>
			);
		},
		[handleSendOnboarding]
	);

	const handleImportUserClose = useCallback(
		(users: Entity[]) => {
			if (users) {
				setAlert(
					<Dialog
						open
						onClose={() => setAlert(null)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							Deseja enviar notificação de onboarding para os {users.length}{" "}
							usuário(s) importado(s)?
						</DialogTitle>
						<DialogActions>
							<Button
								onClick={() => handleConfirmSendOnboarding(users)}
								color="primary"
								autoFocus
							>
								Sim
							</Button>
							<Button onClick={() => setAlert(null)} color="primary">
								Não
							</Button>
						</DialogActions>
					</Dialog>
				);
			}

			setImportUserData(null);
		},
		[handleConfirmSendOnboarding]
	);

	const handleAddUserClose = useCallback(
		(user: any) => {
			if (user) {
				setValue({
					item: {
						...item,
						users: [...item.users, { ...user.businesses[0], user }]
					}
				});

				setAlert(
					<Dialog
						open
						onClose={() => setAlert(null)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							Deseja enviar notificação de onboarding para o usuário?
						</DialogTitle>
						<DialogActions>
							<Button
								onClick={() => handleConfirmSendOnboarding([user])}
								color="primary"
								autoFocus
							>
								Sim
							</Button>
							<Button onClick={() => setAlert(null)} color="primary">
								Não
							</Button>
						</DialogActions>
					</Dialog>
				);
			}

			setShowAddUser(false);
		},
		[handleConfirmSendOnboarding, item, setValue]
	);

	const columns = useMemo(
		() => [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 150,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.name,
				accessor: "fullName",
				minWidth: 250
			},
			{
				Header: ls.email,
				id: "email",
				accessor: c => (c.email ? c.email : ls.notInformed),
				width: 200
			},
			{
				Header: ls.firstActivationDate,
				id: "firstActivationDate",
				accessor: c =>
					c.cardFirstActivationDate
						? c.cardFirstActivationDate
						: ls.notInformed,
				width: 200
			},
			{
				Header: ls.lastActivationDate,
				id: "lastActivationDate",
				accessor: c =>
					c.cardLastActivationDate ? c.cardLastActivationDate : ls.notInformed,
				width: 200
			},
			{
				Header: ls.phoneNumber,
				id: "phoneNumber",
				accessor: c => (c.phoneNumber ? c.phoneNumber : ls.notInformed),
				width: 150
			},
			{
				Header: ls.birthDate,
				id: "birthDate",
				accessor: c =>
					c.birthDate
						? dayjs(c.birthDate).format(ls.dateFormatShort)
						: ls.notInformed,
				minWidth: 250
			},
			{
				Header: ls.mothersName,
				accessor: "mothersName",
				minWidth: 250
			},
			{
				Header: ls.admissionDate,
				id: "admissionDate",
				accessor: c =>
					c.admissionDate
						? dayjs(c.admissionDate).format(ls.dateFormatWithoutSeconds)
						: ls.notInformed,
				width: 150,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.costCenter,
				id: "costCenter",
				accessor: c => (c.costCenter ? c.costCenter : ls.notInformed),
				width: 160,
				show: true
			},
			{
				Header: ls.role,
				id: "roleId",
				accessor: c => ls[c.business?.role?.name] ?? ls.none,
				width: 160,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{roles.map(c => (
							<option key={c.id} value={c.id}>
								{ls[c.name] ?? c.name}
							</option>
						))}
					</select>
				)
			},
			{
				Header: ls.city,
				id: "city",
				accessor: c => (c.address ? c.address.city : ls.notInformed),
				width: 140
			},
			{
				Header: ls.state,
				id: "state",
				accessor: c => (c.address ? c.address.state : ls.notInformed),
				width: 80
			},
			{
				Header: ls.kycStatus,
				id: "kycStatus",
				accessor: c => ls[userKycStatus[c.swapDetails?.kycStatus]],
				width: 200,
				show: false,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(userKycStatus).map(c => (
							<option key={c} value={c}>
								{ls[userKycStatus[c]]}
							</option>
						))}
					</select>
				)
			}
		],
		[roles]
	);

	const handleFetchData = useCallback(
		(tableState, _instance) => {
			if (!isLoaded) {
				return;
			}

			if (!tableState) {
				return;
			}

			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getUsersDebounced) {
				getUsersDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");
			let admissionDate = filtered.find(c => c.id === "admissionDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");
				let date = null;

				if (creationDate?.value.startDate || creationDate?.value.endDate) {
					date = {
						startAt: creationDate.value.startDate,
						endAt: creationDate.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "creationDate",
						value: JSON.stringify(date)
					});
				}
			}

			if (admissionDate?.value) {
				filtered = filtered.filter(c => c.id !== "admissionDate");
				let date = null;

				if (admissionDate?.value.startDate || admissionDate?.value.endDate) {
					date = {
						startAt: admissionDate.value.startDate,
						endAt: admissionDate.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "admissionDate",
						value: JSON.stringify(date)
					});
				}
			}

			const statusFilter = getAllUsers ? "all" : "1";

			getUsersDebounced = _.debounce(
				() =>
					dispatch(
						getUsers(
							page * pageSize,
							pageSize,
							`&filters[businessId]=${
								item.id
							}&filters[status]=${statusFilter}${filtered.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								""
							)}`,
							sorted[0]?.id,
							sorted[0]?.desc,
							toExport,
							callback
						)
					),
				500
			);

			getUsersDebounced();
		},
		[dispatch, getAllUsers, item.id, isLoaded]
	);

	const load = useCallback(
		() => handleFetchData(table.current?.state, null),
		[handleFetchData]
	);

	const handleExportUsersData = useCallback(() => {
		dispatch(
			exportUsersData(item.id, err => {
				if (err) {
					toastError(`Erro ao exportar os dados dos usuários: ${err.default}`);
				}
			})
		);
	}, [dispatch, item.id]);

	useEffect(() => {
		if (isLoaded) {
			load();
		}
	}, [isLoaded, load]);

	const handleUsersToogle = (status: boolean) => {
		const statusFilter = status ? "all" : "1";

		dispatch(
			getUsers(
				null,
				null,
				`&filters[businessId]=${item.id}&filters[status]=${statusFilter}`,
				null,
				null,
				null,
				null
			)
		);

		setGetAllUsers(status);
	};

	return (
		<LoadOnScroll load={loadData}>
			{!item ? (
				<></>
			) : (
				<>
					<Report
						manual
						tableRef={table}
						title={getAllUsers ? `${ls.all}  ${ls.users}` : ls.enabledUsers}
						componentTitle={
							<Switch
								id="getUsersToogle"
								name="getUsersToogle"
								value={getAllUsers}
								checked={getAllUsers}
								onChange={() => {
									handleUsersToogle(!getAllUsers);
								}}
								color="primary"
							/>
						}
						openModalTrash={modalTrash}
						handleChangeModalTrash={handleChangeModalTrash}
						data={addCheckDataUsers}
						pages={state.pages}
						loading={state.loading}
						onFetchData={handleFetchData}
						filterable
						showExport
						headerRightComponent={
							<div>
								<>
									{hasHCMIntegrator && (
										<Button
											aria-haspopup="true"
											onClick={handleOpenModalConsultHCM}
											style={{ marginRight: 5, color: "#5734D9" }}
										>
											Consulta HCM
										</Button>
									)}
									<a
										href="https://docs.google.com/spreadsheets/d/1SSk3Mk5HzXV6GPDjvo1UDZUK-V0sJmFYzyuSePyfTvc/edit?usp=sharing"
										target="_blank"
										rel="noopener noreferrer"
										style={{ marginRight: 16 }}
									>
										Baixar modelo de importação
									</a>
									<TooltipLight title="Importar usuários" placement="top">
										<IconButton onClick={() => handleImportUser()}>
											<FaFileImport />
										</IconButton>
									</TooltipLight>
								</>

								<TooltipLight title="Adicionar usuário" placement="top">
									<IconButton
										color="primary"
										onClick={() => setShowAddUser(true)}
									>
										<FaPlus />
									</IconButton>
								</TooltipLight>

								<TooltipLight title="Exportar limites" placement="top">
									<IconButton color="default" onClick={handleExportUsersData}>
										{loading ? (
											<Spinner color="secondary" size={16} />
										) : (
											<FaFileExport />
										)}
									</IconButton>
								</TooltipLight>
								<TooltipLight
									title="Extrato com os saldos dos usuários"
									placement="top"
								>
									<IconButton color="default" onClick={sendEmailBalanceUsers}>
										{loadingMail ? (
											<Spinner color="secondary" size={16} />
										) : (
											<IoMdMail />
										)}
									</IconButton>
								</TooltipLight>
							</div>
						}
						defaultFilterMethod={(filter, row) =>
							String(row[filter.id])
								.toLowerCase()
								.indexOf(filter.value.toLowerCase()) > -1
						}
						columns={columns}
						onRowClicked={row => window.open(`/User/${row.id}`, "_blank")}
						defaultSorted={[
							{
								id: "creationDate",
								desc: true
							}
						]}
					/>

					<AddUser
						open={showAddUser}
						business={item.id}
						hasHCMIntegrator={hasHCMIntegrator}
						hasWiipoClub={item.hasWiipoClub}
						handleClose={handleAddUserClose}
						load={load}
					/>
					<ImportUsers
						open={Boolean(importUserData)}
						items={importUserData}
						handleClose={handleImportUserClose}
						hasWiipoClub={item.hasWiipoClub}
						load={load}
					/>
					<ConsultHCM
						open={openModalConsultHCM}
						id={item.id}
						handleToast={handleToast}
						handleClose={handleCloseModalConsultHCM}
					/>
					<input
						ref={inputRef}
						style={{ display: "none" }}
						type="file"
						accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						onChange={handleFilesChange}
					/>
					{alertComponent}
				</>
			)}
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(Users);
